import { Link, useParams } from "react-router-dom"
import axios from "axios"
import placeholder from '../../../image/placeholder.jpg'
import { ChangeEvent, useContext, useEffect, useState } from "react"
import { GlobalContext } from "../../../context/GlobalContext"
import { Integrations, SellerItem } from "../../../classes"
import { Loader } from "../../../component/Loader"
import { Pagination } from "../../../component/Pagination"
import xlsx from "json-as-xlsx"

export const SellerItemPage = () => {
    const auth = useContext(GlobalContext)   
    const { id } = useParams()
    const [seller, setSeller] = useState(new SellerItem())


    const [loadIntegration, setLoadIntegration] = useState(true)
    const [integration, setIntegration] = useState(new Integrations())
    const LoadIntegration = (page = 1) => {
        setLoadIntegration(true)
        integration.LoadData(
            auth.user.userToken, 
            "", 
            page,
            "", 
            "", 
            0,
            0,
            0,
            0,
            0,
            0,
            "",
            false,
            0,
            0,
            seller.pk
        ).then(function(response){
            setLoadIntegration(false)
        }).catch(function(error){
            setLoadIntegration(false)
        })
    }
    

    const [load, setLoad] = useState(true)
    const [loadForm, setLoadForm] = useState(true)
    const [loadForm2, setLoadForm2] = useState(true)


    const [targetItem, setTargetItem] = useState(0)

    const [delIntegrationsConf, setDelIntegrationsConf] = useState(false)


    const [form, setForm] = useState({
        name: "",
        tglogin: "",
        phone: "",
        minidesc: "",
        image: "",
        active: true,
        bDate: "",
        desc: "",
        doc: "",
        docConf: false,
        infoConf: false,
        phoneConf: false,
        goodreview: "",
        badreview: "",
        subscribeDate: "",
        integrationsCount: ""
    })
    const changeHandlerUpdate = (event: ChangeEvent<HTMLInputElement>) => {
        setForm({ ...form, [event.target.name]: event.target.value })
    }
    function handlerImage(e: any){
        setForm({ ...form, [e.target.name]: e.target.files[0] })
        console.log(e.target.files)
    }
    const [showFormUpdate, setShowFormUpdate] = useState(false)
    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => { 
        event.preventDefault()
        if(loadForm){
            setLoadForm(false)

            console.log(form)
            const formData = new FormData() 


            formData.append("name", form.name) 
            formData.append("tglogin", form.tglogin) 
            formData.append("phone", form.phone) 
            formData.append("minidesc", form.minidesc) 
            formData.append("image", form.image) 
            formData.append("active", `${form.active}`) 
            formData.append("bDate", form.bDate) 
            formData.append("desc", form.desc) 
            formData.append("doc", form.doc) 
            formData.append("docConf", `${form.docConf}`) 
            formData.append("infoConf", `${form.infoConf}`) 
            formData.append("phoneConf", `${form.phoneConf}`) 
            formData.append("goodreview", form.goodreview) 
            formData.append("badreview", form.badreview) 
            formData.append("subscribeDate", form.subscribeDate) 
            formData.append("integrationsCount", form.integrationsCount) 

            await axios.post(`https://api.blogerito.ru/dashboard/seller-update/`, formData, {
                params: {
                    'id': seller.pk
                },
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': 'Token '+auth.user.userToken
                }
            }).then(res => {  
                setLoadForm(true)
                alert(res.data.msg) 
                window.location.reload()
            }).catch(error => {
                setLoadForm(true)
                alert("error")
            })
        }
    }

    const DelIntegrations = (array: number[]) => {
        seller.DelItem(array, auth.user.userToken).then(function(response){
            alert(`${array.length} селлеров успешно удалено`)
            window.location.reload()
        }).catch(function(error){
            alert("error")
        })
    }


    const LoadExcel = () => {
        integration.LoadExcel(
            auth.user.userToken,
            "", 
            "", 
            "", 
            0,
            0,
            0,
            0,
            0,
            0,
            "",
            false,
            0,
            0,
            "",
            seller.pk
        ).then(function(response){
            const data = [
                {
                    sheet: "Adults",
                    columns: [
                        { label: "id", value: "id" },
                        { label: "Дата создания", value: "date" },
                        { label: "Название интеграции", value: "title" },
                        { label: "Селлер", value: "user" },
                        { label: "Количество интеграций в проект создано селлером", value: "w8_bloger" },
                        { label: "Количество откликов на интеграции создано блогерами", value: "w8_seller" },
                        { label: "Количество интеграций создано", value: "inviteCount" },
                        { label: "Количество интеграций в работе", value: "intWork" },
                        { label: "Количество интеграций завершено", value: "intComplite" },
	                    { label: "Количество интеграций отменено", value: "intCancel" },

                        { label: "Статус интеграции (Заявка принята)", value: "in_process"},
                        { label: "Статус интеграции (ТЗ принято)", value: "in_process"},
                        { label: "Статус интеграции (Ожидание реквизитов)", value: "w8-paydata"},
                        { label: "Статус интеграции (Ожидание оплаты)", value: "payment_w8"},
                        { label: "Статус интеграции (Блогер подтвердил оплату)", value: "payment_completed"},
                        { label: "Статус интеграции (Товар заказан)", value: "product_ordered"},
                        { label: "Статус интеграции (Товар получен)", value: "product_received"},
                        { label: "Статус интеграции (Пост размещен)", value: "post_posted"},
                        { label: "Статус интеграции (Блогер подтвердил выполнение)", value: "bloger_done"},
                        { label: "Статус интеграции (Ожидание отзыва от блогера)", value: "w8_bloger_feedback"},
                    ],
                    content: response
                }, 
            ]
            console.log(data)
            let settings = {
            fileName: "Проекты"
            }
            xlsx(data, settings)
        })
    }
    
    useEffect(()=>{
        seller.pk = Number(id)
        seller.LoadData(auth.user.userToken).then(function(response){
            LoadIntegration()
            setLoad(false)
        }).catch(function(error){
            alert("error")
        })
    },[])

    if(load){
        return(
            <Loader /> 
        )
    }

    return(
        <>
            <div className="content">
                <div className="row">
                    <div className="col-md-6">
                        <div className="block-wrapper bread">
                            <Link to={'/'}>
                                Селлеры
                            </Link>
                            {">"}
                            <span>
                                Селлеры детально
                            </span>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="right">
                            {
                                auth.user.userRole == "director" || auth.user.userRole == "sellerManager" ? (
                                    <>
                                        <button 
                                            className="btn" 
                                            style={{marginTop: 0, background: "#9b51e0"}}
                                            onClick={()=>{
                                                setShowFormUpdate(true)
                                                setLoadForm2(true)
                                                seller.LoadStat(auth.user.userToken).then(function(response: any){
                                                    console.log({...response.msg})
                                                    setForm({...response.msg})
                                                    setLoadForm2(false)
                                                }).catch(function(error: any){
                                                    alert("error")
                                                })

                                                setShowFormUpdate(true)
                                            }}
                                        >
                                            Редактировать
                                        </button> 
                                        <button 
                                            className="btn" 
                                            style={{marginTop: 0, background: "#ff4646"}}
                                            onClick={()=>{
                                                setDelIntegrationsConf(true)
                                            }}
                                        >
                                            Удалить
                                        </button>
                                    </>
                                ):""
                            }
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <div className="block-wrapper desc-wrapper">
                            <div className="product-name">
                                <div className="img">
                                    <div style={{backgroundImage: `url(${seller.image})`}}></div>
                                </div>
                                <div className="desc">
                                    <div className="grid">
                                        <div>
                                            <h3>
                                                {seller.name}
                                            </h3>
                                            <p>
                                                <span>Email: </span> <Link to={`mailto:${seller.user.email}`}>{seller.user.email}</Link><br/>
                                                <span>Telegram: </span>{seller.tglogin}<br/>
                                                <span>Номер телефона: </span> <Link to={`tel:${seller.phone}`}>{seller.phone}</Link>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="block-wrapper desc-wrapper">
                            <p>
                                <span>Отзывы +:</span> {seller.goodreview}
                            </p>
                            <p>
                                <span>Отзывы -:</span>  {seller.badreview}
                            </p>
                            <p>
                                <span>Кол-во доступных интеграций:</span> {seller.integrationsCount}
                            </p>
                            <p>
                                <span>Дата завершения подписки:</span> {seller.subscribeDate}
                            </p>
                            <p>
                                <span>Дата рождения:</span> {seller.bDate}
                            </p>

                            <p>
                                <span>Документы подтверждены:</span> {seller.docConf ? "Да":"Нет"}
                            </p>
                            <p>
                                <span>Аккаунт подтвержден:</span> {seller.infoConf ? "Да":"Нет"}
                            </p>
                            <p>
                                <span>Телефон подтвержден:</span> {seller.phoneConf ? "Да":"Нет"}
                            </p>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="block-wrapper desc-wrapper">
                            <p>
                                <span>Кол-во созданных проектов: {seller.integrations}</span>
                            </p>
                            <p>
                                <span>Кол-во интеграций подтверждено: {seller.applications_proccess}</span>
                            </p>
                            <p>
                                <span>Кол-во отмененых интеграций: {seller.applications_cancelled}</span>
                            </p>
                            <p>
                                <span>Кол-во интеграций в работе: {seller.applications_work}</span>
                            </p>
                            <p>
                                <span>Кол-во интеграций (Заявка принята):</span> {seller.in_process}
                            </p>
                            <p>
                                <span>Кол-во интеграций (ТЗ принято):</span> {seller.tz_conf}
                            </p>
                            <p>
                                <span>Кол-во интеграций (Ожидание реквизитов):</span> {seller.w8paydata}
                            </p>
                            <p>
                                <span>Кол-во интеграций (Ожидание оплаты):</span> {seller.payment_w8}
                            </p>
                            <p>
                                <span>Кол-во интеграций (Блогер подтвердил оплату):</span> {seller.payment_completed}
                            </p>
                            <p>
                                <span>Кол-во интеграций (Товар заказан):</span> {seller.product_ordered}
                            </p>
                            <p>
                                <span>Кол-во интеграций (Товар получен):</span> {seller.product_received}
                            </p>
                            <p>
                                <span>Кол-во интеграций (Пост размещен):</span> {seller.post_posted}
                            </p>
                            <p>
                                <span>Кол-во интеграций (Блогер подтвердил выполнение):</span> {seller.bloger_done}
                            </p>
                            <p>
                                <span>Кол-во интеграций (Ожидание отзыва от блогера):</span> {seller.w8_bloger_feedback}
                            </p>
                            <p>
                                <span>Процент завершенных интеграций:</span> {Math.round(seller.applications_done / (seller.applications_proccess / 100))}%
                            </p>
                        </div>
                    </div>


                    <div className="col-md-4">
                    </div>
                    <div className="col-md-8">
                        <div className="right">

                            {
                                auth.user.userRole == "director" ? (
                                    <button 
                                        className="btn" 
                                        style={{marginBottom: 25, background: "#207444"}}
                                        onClick={()=>{
                                            LoadExcel()
                                        }}
                                    >
                                        Выгрузить в excel
                                    </button>
                                ):""
                            }
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="block-wrapper table-wrapper">
                            <h3>Проекты</h3>
                            {
                                !loadIntegration ? (
                                    <table className="list-table">
                                        <thead>
                                            <tr>
                                                <th>
                                                    id
                                                </th>
                                                <th>
                                                    Название проекта
                                                </th>
                                                <th>
                                                    Количество возможных интеграций
                                                </th> 
                                                <th>
                                                    Дата размещения
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                integration.list ? (
                                                    integration.list.map((item, index) => {
                                                        return(
                                                            <tr>
                                                                <td>
                                                                    <div>
                                                                        <div>
                                                                            <Link to={`/integration/${item.pk}/`}>
                                                                                {item.pk}
                                                                            </Link>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div>
                                                                        {item.title}
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div>
                                                                        {item.maxIntegrations}
                                                                    </div>
                                                                </td> 
                                                                <td>
                                                                    <div>
                                                                        {item.date}
                                                                    </div>
                                                                </td> 
                                                            </tr>
                                                        )
                                                    })
                                                ):""
                                            } 
                                        </tbody>
                                    </table>
                                ):(
                                    <p>Загрузить...</p>
                                )
                            }
                        </div>
                        <Pagination
                            page={integration.targetPage}
                            pageCount={integration.countPage}
                            loadData={LoadIntegration}
                        />
                    </div>




                    <div className="col-12"> 
                        <div className="block-wrapper table-wrapper">
                            <h3>Оплаты</h3>
                            <table className="list-table">
                                <thead>
                                    <tr>
                                        <th>
                                            id
                                        </th>
                                        <th>
                                            Название тарифа
                                        </th>
                                        <th>
                                            Дата
                                        </th> 
                                        <th>
                                            Статус
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        seller.pay.map((item: any, index: number) => {
                                            return(
                                                <tr>
                                                    <td>
                                                        <div>
                                                            <div>
                                                                {item.pk}
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div>
                                                            {item.title}
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div>
                                                            {item.date}
                                                        </div>
                                                    </td>  
                                                    <td>
                                                        <div>
                                                            {item.status}
                                                        </div>
                                                    </td> 
                                                </tr> 
                                            )
                                        })
                                    } 
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>


            {
                showFormUpdate ? (
                    <div className="popup-integration">
                        <div className="">
                            <div style={{textAlign: "right"}}>
                                <svg 
                                    width="30px" 
                                    height="30px" 
                                    viewBox="0 0 24 24" 
                                    fill="none" 
                                    xmlns="http://www.w3.org/2000/svg"
                                    style={{
                                        cursor: "pointer"
                                    }}
                                    onClick={()=>{
                                        setShowFormUpdate(false)
                                    }}
                                >
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M5.29289 5.29289C5.68342 4.90237 6.31658 4.90237 6.70711 5.29289L12 10.5858L17.2929 5.29289C17.6834 4.90237 18.3166 4.90237 18.7071 5.29289C19.0976 5.68342 19.0976 6.31658 18.7071 6.70711L13.4142 12L18.7071 17.2929C19.0976 17.6834 19.0976 18.3166 18.7071 18.7071C18.3166 19.0976 17.6834 19.0976 17.2929 18.7071L12 13.4142L6.70711 18.7071C6.31658 19.0976 5.68342 19.0976 5.29289 18.7071C4.90237 18.3166 4.90237 17.6834 5.29289 17.2929L10.5858 12L5.29289 6.70711C4.90237 6.31658 4.90237 5.68342 5.29289 5.29289Z" fill="#0F1729"/>
                                </svg>
                            </div>
                            {
                                loadForm2 ? (
                                    <div style={{backgroundColor: "white", borderRadius: 10, paddingTop: 20}}>
                                        <Loader />
                                    </div>
                                ):(
                                    <form onSubmit={handleSubmit}> 
                                        <label> 
                                            <input 
                                                type="checkbox" 
                                                checked={form.active} 
                                                onChange={()=>{setForm({...form, active: !form.active})}} 
                                                style={{
                                                    width: "auto",
                                                    marginRight: 5
                                                }}
                                            />
                                            Селлер активный
                                        </label>
                                        <label> 
                                            ФИО
                                            <input 
                                                type="text" 
                                                maxLength={255}
                                                required 
                                                style={{backgroundColor: "white !important", color: "black !important"}} 
                                                name="name" 
                                                value={form.name} 
                                                onChange={changeHandlerUpdate}
                                            />
                                        </label>
                                        <label> 
                                            Логин в telegram
                                            <input 
                                                type="text" 
                                                maxLength={255}
                                                required 
                                                style={{backgroundColor: "white !important", color: "black !important"}} 
                                                name="tglogin" 
                                                value={form.tglogin} 
                                                onChange={changeHandlerUpdate}
                                            />
                                        </label>
                                        <label> 
                                            Номер телефона
                                            <input 
                                                type="text" 
                                                maxLength={255}
                                                required 
                                                style={{backgroundColor: "white !important", color: "black !important"}} 
                                                name="phone" 
                                                value={form.phone} 
                                                onChange={changeHandlerUpdate}
                                            />
                                        </label>
                                        <label>
                                            Фото
                                            <input 
                                                type="file"
                                                name="image"
                                                style={{backgroundColor: "white !important", color: "black !important"}} 
                                                accept="image/*"
                                                onChange={handlerImage}
                                            />
                                        </label>
                                        <label>
                                            Краткое описание
                                            <textarea name="minidesc" maxLength={510} value={form.minidesc} onChange={(e)=>{
                                                setForm({ ...form, "minidesc": e.target.value })
                                            }} />
                                        </label>
                                        <label> 
                                            Дата рождения
                                            <input 
                                                type="date" 
                                                maxLength={255} 
                                                style={{backgroundColor: "white !important", color: "black !important"}} 
                                                name="bDate" 
                                                value={form.bDate} 
                                                onChange={changeHandlerUpdate}
                                            />
                                        </label>
                                        <label>
                                            Полный текст о вас
                                            <textarea name="desc" value={form.desc} onChange={(e)=>{
                                                setForm({ ...form, "desc": e.target.value })
                                            }} />
                                        </label>
                                        <label>
                                            Фото
                                            <input 
                                                type="file"
                                                name="doc"
                                                style={{backgroundColor: "white !important", color: "black !important"}} 
                                                accept="image/*"
                                                onChange={handlerImage}
                                            />
                                        </label>
                                        <label> 
                                            Дата завершения подписки
                                            <input 
                                                type="date" 
                                                maxLength={255} 
                                                style={{backgroundColor: "white !important", color: "black !important"}} 
                                                name="subscribeDate" 
                                                value={form.subscribeDate} 
                                                onChange={changeHandlerUpdate}
                                            />
                                        </label>
                                        <label> 
                                            <input 
                                                type="checkbox" 
                                                checked={form.docConf} 
                                                onChange={()=>{setForm({...form, docConf: !form.docConf})}} 
                                                style={{
                                                    width: "auto",
                                                    marginRight: 5
                                                }}
                                            />
                                            Документы подтверждены
                                        </label>
                                        <label> 
                                            <input 
                                                type="checkbox" 
                                                checked={form.infoConf} 
                                                onChange={()=>{setForm({...form, infoConf: !form.infoConf})}} 
                                                style={{
                                                    width: "auto",
                                                    marginRight: 5
                                                }}
                                            />
                                            Аккаунт подтвержден
                                        </label>
                                        <label> 
                                            <input 
                                                type="checkbox" 
                                                checked={form.phoneConf} 
                                                onChange={()=>{setForm({...form, phoneConf: !form.phoneConf})}} 
                                                style={{
                                                    width: "auto",
                                                    marginRight: 5
                                                }}
                                            />
                                            Телефон подтверждены
                                        </label>
                                        <label> 
                                            Количество доступных интеграций
                                            <input 
                                                type="number"
                                                min={0}
                                                step={1} 
                                                required
                                                style={{backgroundColor: "white !important", color: "black !important"}} 
                                                name="integrationsCount" 
                                                value={form.integrationsCount} 
                                                onChange={changeHandlerUpdate}
                                            />
                                        </label>

                                        <button>Обновить селлера</button> 
                                    </form>
                                )
                            }
                        </div>
                    </div>
                ):""
            }


{
                delIntegrationsConf ? (
                    <div className="popup-error">
                        <div>
                            <div style={{textAlign: "right"}}>
                                <svg 
                                    width="30px" 
                                    height="30px" 
                                    viewBox="0 0 24 24" 
                                    fill="none" 
                                    xmlns="http://www.w3.org/2000/svg"
                                    style={{
                                        cursor: "pointer"
                                    }}
                                    onClick={()=>{
                                        setDelIntegrationsConf(false)
                                    }}
                                >
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M5.29289 5.29289C5.68342 4.90237 6.31658 4.90237 6.70711 5.29289L12 10.5858L17.2929 5.29289C17.6834 4.90237 18.3166 4.90237 18.7071 5.29289C19.0976 5.68342 19.0976 6.31658 18.7071 6.70711L13.4142 12L18.7071 17.2929C19.0976 17.6834 19.0976 18.3166 18.7071 18.7071C18.3166 19.0976 17.6834 19.0976 17.2929 18.7071L12 13.4142L6.70711 18.7071C6.31658 19.0976 5.68342 19.0976 5.29289 18.7071C4.90237 18.3166 4.90237 17.6834 5.29289 17.2929L10.5858 12L5.29289 6.70711C4.90237 6.31658 4.90237 5.68342 5.29289 5.29289Z" fill="#0F1729"/>
                                </svg>
                            </div>
                            <h3>Вы уверены, что хотите удалить селлеров? </h3>
                            <div className="btn-wrapper">
                                <button
                                    onClick={()=>{
                                        DelIntegrations([seller.pk])
                                    }}
                                >
                                    Да
                                </button>
                                <button
                                    onClick={()=>{
                                        setDelIntegrationsConf(false)
                                    }}
                                >
                                    Отменить
                                </button>
                            </div> 
                        </div>
                    </div>
                ):""
            }

        </>
    )
}