import { Link, useParams, useSearchParams } from "react-router-dom"
import axios from "axios"
import xlsx from "json-as-xlsx"
import placeholder from '../../../image/placeholder.jpg'
import { ChangeEvent, useContext, useEffect, useState } from "react"
import { BlogerItem } from "../../../classes"
import { GlobalContext } from "../../../context/GlobalContext"
import { Loader } from "../../../component/Loader"
import { Pagination } from "../../../component/Pagination"

export const BlogerItemPage = () => {
    const auth = useContext(GlobalContext)   
    const { id } = useParams()
    const [bloger, setBloger] = useState(new BlogerItem())
    const [load, setLoad] = useState(true)

    const [blockInfo, setBlockInfo] = useState({
        time: 'day',
        reason: ""
    })

    const [loadForm, setLoadForm] = useState(true)
    const [loadForm2, setLoadForm2] = useState(true)
    const [loadForm3, setLoadForm3] = useState(true)

    const [delIntegrationsConf, setDelIntegrationsConf] = useState(false)
    const [showFormUpdate, setShowFormUpdate] = useState(false)
    
    const [showFormBlock, setShowFormBlock] = useState(false)


    const [form, setForm] = useState({
        active: false,
        tglogin: "",
        name: "",
        phone: "",
        image: "",
        bDate: "",
        city: "",
        minidesc: "",
        category: "",
        regLink: "",
        youtube: "",
        vk: "",
        instagram: "",
        rutube: "",
        telegram: "",
        desc: "",
        doc: "",
        subscribers: "",
        men: "",
        woman: "",
        docConf: false,
        infoConf: false,
        phoneConf: false,
        CatalogConf: false,
        integrationCost: ""
    })
    const changeHandlerUpdate = (event: ChangeEvent<HTMLInputElement>) => {
        setForm({ ...form, [event.target.name]: event.target.value })
    }
    function handlerImage(e: any){
        setForm({ ...form, [e.target.name]: e.target.files[0] })
        console.log(e.target.files)
    }
    
    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => { 
        event.preventDefault()
        if(loadForm){
            setLoadForm(false)

            console.log(form)
            const formData = new FormData() 

            formData.append("active", `${form.active}`)
            formData.append("tglogin", form.tglogin)
            formData.append("name", form.name)
            formData.append("phone", form.phone)
            formData.append("image", form.image)
            formData.append("bDate", form.bDate)
            formData.append("city", form.city)
            formData.append("minidesc", form.minidesc)
            formData.append("category", form.category)
            formData.append("regLink", form.regLink)
            formData.append("youtube", form.youtube)
            formData.append("vk", form.vk)
            formData.append("instagram", form.instagram)
            formData.append("rutube", form.rutube)
            formData.append("telegram", form.telegram)
            formData.append("desc", form.desc)
            formData.append("doc", form.doc)
            formData.append("subscribers", form.subscribers)
            formData.append("men", form.men)
            formData.append("woman", form.woman)
            formData.append("docConf", `${form.docConf}`)
            formData.append("infoConf", `${form.infoConf}`)
            formData.append("phoneConf", `${form.phoneConf}`)
            formData.append("CatalogConf", `${form.CatalogConf}`)
            formData.append("integrationCost", form.integrationCost)

            await axios.post(`https://api.blogerito.ru/dashboard/bloger-update/`, formData, {
                params: {
                    'id': bloger.pk
                },
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': 'Token '+auth.user.userToken
                }
            }).then(res => {  
                setLoadForm(true)
                alert(res.data.msg) 
                window.location.reload()
            }).catch(error => {
                setLoadForm(true)
                alert("error")
            })
        }
    }

    const handleSubmitForm = async (event: React.FormEvent<HTMLFormElement>) => { 
        event.preventDefault()

        if(loadForm3){
            setLoadForm3(false)
            bloger.Blocked(auth.user.userToken, false, blockInfo.time, blockInfo.reason).then(function(response){
                alert(response)
                window.location.reload()
            }).catch(function(error){
                alert("error")
            })
        }
    }


    const [loadApplic, setLoadApplic] = useState(true)
    const LoadApplications = (page: number = 1) => {
        setLoadApplic(true)
        bloger.LoadApplications(auth.user.userToken, page).then(function(response){
            setLoadApplic(false)
        }).catch(function(error){
            alert("error")
            setLoadApplic(false)
        })
    }

    const ToExcel = () => {
        bloger.LoadApplications(auth.user.userToken, 1, true).then(function(response){
            const data = [
                {
                    sheet: "Adults",
                    columns: [
                        { label: "id", value: "id" },
                        { label: "Дата создания", value: "date" },
                        { label: "Статус заявки", value: "status" },
                        { label: "Дата изменения статуса", value: "statusDate" },
                        { label: "id проекта", value: "integration_id" },
                        { label: "Название проекта", value: "integration_title" }
                    ],
                    content: response
                }, 
            ]
            let settings = {
                fileName: "Заявки блогера"
            }
            xlsx(data, settings)
        }).catch(function(error){
            alert("error")
            setLoadApplic(false)
        })
    }

    const DelIntegrations = () => {
        bloger.DelItem(auth.user.userToken).then(function(response){
            alert(`Блогер успешно удален`)
            //window.location.reload()
            window.location.replace('/');
        }).catch(function(error){
            alert("error")
        })
    }

    useEffect(()=>{
        bloger.pk = Number(id)
        bloger.LoadData(auth.user.userToken).then(function(response){
            setLoad(false)
        }).catch(function(error){
            alert("error")
        })
        LoadApplications()
    },[])

    if(load){
        return(
            <Loader /> 
        )
    }

    return(
        <>
            <div className="content">
                <div className="row">
                    <div className="col-md-6">
                        <div className="block-wrapper bread">
                            <Link to={'/'}>
                                Блогеры
                            </Link>
                            {">"}
                            <span>
                                Блогер детально
                            </span>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="right"> 
                            {
                                auth.user.userRole == "director" || auth.user.userRole == "blogerManager" ? (
                                    <>
                                        <button 
                                            className="btn" 
                                            style={{marginTop: 0, background: "#ff4646"}}
                                            onClick={()=>{
                                                if(!bloger.blogerBlock){
                                                    setShowFormBlock(true)
                                                }else
                                                    bloger.Blocked(auth.user.userToken, false, "", "").then(function(response){
                                                        alert(response)
                                                        window.location.reload()
                                                    }).catch(function(error){
                                                        alert("error")
                                                    })
                                            }}
                                        >
                                            {!bloger.blogerBlock ? "Заблокировать":"Разблокировать"}
                                        </button>
                                        <button 
                                            className="btn" 
                                            style={{marginTop: 0, background: "#9b51e0"}}
                                            onClick={()=>{
                                                setShowFormUpdate(true)
                                                setLoadForm2(true)
                                                bloger.LoadStat(auth.user.userToken).then(function(response: any){
                                                    console.log({...response.msg})
                                                    setForm({...response.msg})
                                                    setLoadForm2(false)
                                                }).catch(function(error: any){
                                                    alert("error")
                                                })

                                                setShowFormUpdate(true)
                                            }}
                                        >
                                            Редактировать
                                        </button>
                                        <button 
                                            className="btn" 
                                            style={{marginTop: 0, background: "#ff4646"}}
                                            onClick={()=>{setDelIntegrationsConf(true)}}
                                        >
                                            Удалить
                                        </button>
                                    </>
                                ):""
                            }
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <div className="block-wrapper desc-wrapper">
                            <div className="product-name">
                                <div className="img">
                                    <div style={{backgroundImage: `url(${bloger.image})`}}></div>
                                </div>
                                <div className="desc">
                                    <div className="grid">
                                        <div>
                                            <h3>
                                                {bloger.name}
                                            </h3>
                                            <p>
                                                <span>Email: </span> <Link to={`mailto:${bloger.user.email}`}>{bloger.user.email}</Link><br/>
                                                <span>Номер телефона: </span> <Link to={`tel:${bloger.phone}`}>{bloger.phone}</Link><br/>
                                                <span>Аккаунт подтвержден: </span>{bloger.infoConf == false ? "Нет":"Да"}<br/>
                                                <span>Документы подтверждены: </span>{bloger.docConf == false ? "Нет":"Да"}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="block-wrapper desc-wrapper">
                            <p>
                                <span>Ник в соц сети: </span> {bloger.regLink}
                            </p>
                            <p>
                                <span>Ник в telegram: </span> {bloger.tglogin}
                            </p>
                            <p>
                                <span>Количество подписчиков: </span> {bloger.subscribers}
                            </p>
                            <p>
                                <span>Дата регистрации: </span> {bloger.date}
                            </p>
                            <p>
                                <span>Рейтинг: </span> {bloger.integration}
                            </p>
                            <p>
                                <span>Отзыв +: </span> {bloger.goodreview}
                            </p>
                            <p>
                                <span>Отзыв -: </span> {bloger.badreview}
                            </p>
                            <p>
                                <span>Количество принятых интеграций: </span> {bloger.applications_proccess}
                            </p>
                            <p>
                                <span>Количество интеграций в работе: </span> {bloger.applications_work}
                            </p>
                            <p>
                                <span>Дата последнего захода на сайт: </span> {bloger.dateActivity}
                            </p>


                            <p>
                                <span>Юр.лицо ФИО: </span> {bloger.LegalFIO}
                            </p>
                            <p>
                                <span>Юр.лицо ИНН: </span> {bloger.LegalINN}
                            </p>
                            <p>
                                <span>Консультация (чекбокс): </span> {bloger.LagalConsultation ? "Да":"Нет"}
                            </p>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="block-wrapper desc-wrapper">
                            <p>
                                <span>Количество интеграций (Заявка принята): </span> {bloger.in_process}
                            </p>
                            <p>
                                <span>Количество интеграций (ТЗ принято): </span> {bloger.tz_conf}
                            </p>
                            <p>
                                <span>Количество интеграций (Ожидание реквизитов): </span> {bloger.w8paydata}
                            </p>
                            <p>
                                <span>Количество интеграций (Ожидание оплаты): </span> {bloger.payment_w8}
                            </p>
                            <p>
                                <span>Количество интеграций (Блогер подтвердил оплату): </span> {bloger.payment_completed}
                            </p>
                            <p>
                                <span>Количество интеграций (Товар заказан): </span> {bloger.product_ordered}
                            </p>
                            <p>
                                <span>Количество интеграций (Товар получен): </span> {bloger.product_received}
                            </p>
                            <p>
                                <span>Количество интеграций (Пост размещен): </span> {bloger.post_posted}
                            </p>
                            <p>
                                <span>Количество интеграций (Блогер подтвердил выполнение): </span> {bloger.bloger_done}
                            </p>
                            <p>
                                <span>Количество интеграций (Ожидание отзыва от блогера): </span> {bloger.w8_bloger_feedback}
                            </p>
                            <p>
                                <span>Успешно завершено интеграций: </span> {bloger.applications_done}
                            </p>
                            <p>
                                <span>Отменено интеграций: </span>{bloger.applications_cancelled}
                            </p>
                            <p>
                                <span>Процент завершенных интеграций: </span> {Math.round(bloger.applications_done / (bloger.applications_proccess / 100))}%
                            </p>
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="right" style={{marginBottom: 20}}>

                                    {
                                        auth.user.userRole == "director" ? (
                                            <button 
                                                className="btn" 
                                                style={{background: "#207444"}}
                                                onClick={()=>{
                                                    ToExcel()
                                                }}  
                                            >
                                                Выгрузить в excel
                                            </button>
                                        ):""
                                    }
                                </div>
                                <div className="block-wrapper table-wrapper">
                                    <h3>Заявки в проекты</h3>
                                    {
                                        loadApplic ? (
                                            <p style={{
                                                fontSize: 22,
                                                marginBottom: 25,
                                                marginTop: 30,
                                                fontWeight: "bold"
                                            }}>
                                                Загрузка...
                                            </p>
                                        ):(
                                            <table className="list-table">
                                                <thead>
                                                    <tr>
                                                        <th>
                                                            id
                                                        </th>
                                                        <th>
                                                            Интеграция
                                                        </th>
                                                        <th>
                                                            Статус
                                                        </th> 
                                                        <th>
                                                            Дата создания
                                                        </th>
                                                        <th>
                                                            Дата изменения статуса
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        bloger.list ? (
                                                            bloger.list.map((item: any, index: number) => {
                                                                return(
                                                                    <tr>
                                                                        <td>
                                                                            <div>
                                                                                <div>
                                                                                    {item.id}
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                        <td>
                                                                            <div>
                                                                                <Link to={`/integration/${item.integration.pk}/`} target="_blank">
                                                                                    {item.integration.title}
                                                                                </Link>
                                                                            </div>
                                                                        </td>
                                                                        <td>
                                                                            <div 
                                                                                style={{
                                                                                    color: item.status == "Отменено рекламодателем" || item.status == "Отменено блогером" ? "red" :
                                                                                    item.status != "Ожидает подтверждения от блогера" && item.status != "Ожидает подтверждения от рекламодателя" ? "green" : ""
                                                                                }}
                                                                            >
                                                                                {item.status}
                                                                            </div>
                                                                        </td>  
                                                                        <td>
                                                                            <div>
                                                                                {item.date}
                                                                            </div>
                                                                        </td> 
                                                                        <td>
                                                                            <div>
                                                                                {item.statusDate}
                                                                            </div>
                                                                        </td>
                                                                    </tr> 
                                                                )
                                                            })
                                                        ):""
                                                    }
                                                </tbody>
                                            </table>
                                        )
                                    }

                                </div>
                                {
                                    !loadApplic ? (
                                        <Pagination
                                            page={bloger.targetPage}
                                            pageCount={bloger.countPage}
                                            loadData={LoadApplications}
                                        />
                                    ):""
                                }
                            </div>
                            <div className="col-md-12">
                                <div className="block-wrapper table-wrapper">
                                    <h3>История блокировок</h3>
                                    <table className="list-table" style={{minWidth: 600}}>
                                        <thead>
                                            <tr>
                                                <th>
                                                    id
                                                </th>
                                                <th>
                                                    Дата блокировки
                                                </th>
                                                <th>
                                                    Причина блокировки
                                                </th> 
                                                <th>
                                                    Дата завершенния
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                bloger.blocks ? (
                                                    bloger.blocks.length ? (
                                                        bloger.blocks.map((item: any, index: number) => {
                                                            return(
                                                                <tr>
                                                                    <td>
                                                                        <div>
                                                                            <div>
                                                                                {item.id}
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div>
                                                                            {item.date}
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div>
                                                                            {item.andDate}
                                                                        </div>
                                                                    </td>   
                                                                    <td>
                                                                        <div>
                                                                            {item.reason}
                                                                        </div>
                                                                    </td>   
                                                                </tr>
                                                            )
                                                        })
                                                    ):""
                                                ):""
                                            }
                                        </tbody>
                                    </table> 
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {
                showFormBlock ? (
                    <div className="popup-integration">
                        <div>
                            <div style={{textAlign: "right"}}>
                                <svg 
                                    width="30px" 
                                    height="30px" 
                                    viewBox="0 0 24 24" 
                                    fill="none" 
                                    xmlns="http://www.w3.org/2000/svg"
                                    style={{
                                        cursor: "pointer"
                                    }}
                                    onClick={()=>{
                                        setShowFormBlock(false)
                                    }}
                                >
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M5.29289 5.29289C5.68342 4.90237 6.31658 4.90237 6.70711 5.29289L12 10.5858L17.2929 5.29289C17.6834 4.90237 18.3166 4.90237 18.7071 5.29289C19.0976 5.68342 19.0976 6.31658 18.7071 6.70711L13.4142 12L18.7071 17.2929C19.0976 17.6834 19.0976 18.3166 18.7071 18.7071C18.3166 19.0976 17.6834 19.0976 17.2929 18.7071L12 13.4142L6.70711 18.7071C6.31658 19.0976 5.68342 19.0976 5.29289 18.7071C4.90237 18.3166 4.90237 17.6834 5.29289 17.2929L10.5858 12L5.29289 6.70711C4.90237 6.31658 4.90237 5.68342 5.29289 5.29289Z" fill="#0F1729"/>
                                </svg>
                            </div>
        
                            <form onSubmit={handleSubmitForm}>
                                <label> 
                                    Период блокировки
                                    {
                                        !bloger.blogerBlock ? (
                                            <select 
                                                onChange={(e)=>{
                                                    setBlockInfo({...blockInfo, "time": e.target.value})
                                                }}
                                                style={{
                                                    width: "100%"
                                                }}
                                            >
                                                <option value={'day'} selected={blockInfo.time == 'day' ? true : false}>1 день</option>
                                                <option value={'3day'} selected={blockInfo.time == '3day' ? true : false}>3 дня</option>
                                                <option value={'30day'} selected={blockInfo.time == '30day' ? true : false}>30 дней</option>
                                                <option value={'inf'} selected={blockInfo.time == 'inf' ? true : false}>бессрочно</option>
                                            </select>
                                        ):""
                                    }
                                </label>
        
                                <label> 
                                    Причина блокировки
                                    <input 
                                        type="text" 
                                        maxLength={510}
                                        required 
                                        style={{backgroundColor: "white !important", color: "black !important"}} 
                                        name="reason" 
                                        value={blockInfo.reason} 
                                        onChange={(e)=>{
                                            setBlockInfo({...blockInfo, "reason": e.target.value})
                                        }}
                                    />
                                </label>
        
                                <button>Заблокировать</button>
                            </form>
                        </div>
                    </div>
                ):""
            }

            {
                showFormUpdate ? (
                    <div className="popup-integration">
                        <div className="">
                            <div style={{textAlign: "right"}}>
                                <svg 
                                    width="30px" 
                                    height="30px" 
                                    viewBox="0 0 24 24" 
                                    fill="none" 
                                    xmlns="http://www.w3.org/2000/svg"
                                    style={{
                                        cursor: "pointer"
                                    }}
                                    onClick={()=>{
                                        setShowFormUpdate(false)
                                    }}
                                >
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M5.29289 5.29289C5.68342 4.90237 6.31658 4.90237 6.70711 5.29289L12 10.5858L17.2929 5.29289C17.6834 4.90237 18.3166 4.90237 18.7071 5.29289C19.0976 5.68342 19.0976 6.31658 18.7071 6.70711L13.4142 12L18.7071 17.2929C19.0976 17.6834 19.0976 18.3166 18.7071 18.7071C18.3166 19.0976 17.6834 19.0976 17.2929 18.7071L12 13.4142L6.70711 18.7071C6.31658 19.0976 5.68342 19.0976 5.29289 18.7071C4.90237 18.3166 4.90237 17.6834 5.29289 17.2929L10.5858 12L5.29289 6.70711C4.90237 6.31658 4.90237 5.68342 5.29289 5.29289Z" fill="#0F1729"/>
                                </svg>
                            </div>
                            {
                                loadForm2 ? (
                                    <div style={{backgroundColor: "white", borderRadius: 10, paddingTop: 20}}>
                                        <Loader />
                                    </div>
                                ):(
                                    <form onSubmit={handleSubmit}>
                                        <label> 
                                            <input 
                                                type="checkbox" 
                                                checked={form.active} 
                                                onChange={()=>{setForm({...form, active: !form.active})}} 
                                                style={{
                                                    width: "auto",
                                                    marginRight: 5
                                                }}
                                            />
                                            Блогер активный
                                        </label>
                                        <label> 
                                            Логин в telegram
                                            <input 
                                                type="text" 
                                                maxLength={255}
                                                required 
                                                style={{backgroundColor: "white !important", color: "black !important"}} 
                                                name="tglogin" 
                                                value={form.tglogin} 
                                                onChange={changeHandlerUpdate}
                                            />
                                        </label>
                                        <label> 
                                            ФИО блогера
                                            <input 
                                                type="text" 
                                                maxLength={255}
                                                required 
                                                style={{backgroundColor: "white !important", color: "black !important"}} 
                                                name="name" 
                                                value={form.name} 
                                                onChange={changeHandlerUpdate}
                                            />
                                        </label>
                                        <label> 
                                            Номер телефона
                                            <input 
                                                type="text" 
                                                maxLength={255}
                                                required 
                                                style={{backgroundColor: "white !important", color: "black !important"}} 
                                                name="phone" 
                                                value={form.phone} 
                                                onChange={changeHandlerUpdate}
                                            />
                                        </label>
                                        <label>
                                            Фото
                                            <input 
                                                type="file"
                                                name="image"
                                                style={{backgroundColor: "white !important", color: "black !important"}} 
                                                accept="image/*"
                                                onChange={handlerImage}
                                            />
                                        </label>
                                        <label> 
                                            Дата рождения
                                            <input 
                                                type="date" 
                                                maxLength={255} 
                                                style={{backgroundColor: "white !important", color: "black !important"}} 
                                                name="bDate" 
                                                value={form.bDate} 
                                                onChange={changeHandlerUpdate}
                                            />
                                        </label>
                                        <label> 
                                            Город
                                            <input 
                                                type="text" 
                                                maxLength={255}
                                                required 
                                                style={{backgroundColor: "white !important", color: "black !important"}} 
                                                name="city" 
                                                value={form.city} 
                                                onChange={changeHandlerUpdate}
                                            />
                                        </label>
                                        <label> 
                                            Краткое описание
                                            <input 
                                                type="text" 
                                                maxLength={80} 
                                                style={{backgroundColor: "white !important", color: "black !important"}} 
                                                name="minidesc" 
                                                value={form.minidesc} 
                                                onChange={changeHandlerUpdate}
                                            />
                                        </label>
                                        <label> 
                                            Категория
                                            <input 
                                                type="text" 
                                                maxLength={255} 
                                                style={{backgroundColor: "white !important", color: "black !important"}} 
                                                name="category" 
                                                value={form.category} 
                                                onChange={changeHandlerUpdate}
                                            />
                                        </label>
                                        <label> 
                                            Ссылка на соцсеть*
                                            <input 
                                                type="text" 
                                                maxLength={510}
                                                required 
                                                style={{backgroundColor: "white !important", color: "black !important"}} 
                                                name="regLink" 
                                                value={form.regLink} 
                                                onChange={changeHandlerUpdate}
                                            />
                                        </label>
                                        <label> 
                                            youtube
                                            <input 
                                                type="text" 
                                                maxLength={510} 
                                                style={{backgroundColor: "white !important", color: "black !important"}} 
                                                name="youtube" 
                                                value={form.youtube} 
                                                onChange={changeHandlerUpdate}
                                            />
                                        </label>
                                        <label> 
                                            vk
                                            <input 
                                                type="text" 
                                                maxLength={510} 
                                                style={{backgroundColor: "white !important", color: "black !important"}} 
                                                name="vk" 
                                                value={form.vk} 
                                                onChange={changeHandlerUpdate}
                                            />
                                        </label>
                                        <label> 
                                            instagram
                                            <input 
                                                type="text" 
                                                maxLength={510} 
                                                style={{backgroundColor: "white !important", color: "black !important"}} 
                                                name="instagram" 
                                                value={form.instagram} 
                                                onChange={changeHandlerUpdate}
                                            />
                                        </label>
                                        <label> 
                                            rutube
                                            <input 
                                                type="text" 
                                                maxLength={510} 
                                                style={{backgroundColor: "white !important", color: "black !important"}} 
                                                name="rutube" 
                                                value={form.rutube} 
                                                onChange={changeHandlerUpdate}
                                            />
                                        </label>
                                        <label> 
                                            telegram
                                            <input 
                                                type="text" 
                                                maxLength={510} 
                                                style={{backgroundColor: "white !important", color: "black !important"}} 
                                                name="telegram" 
                                                value={form.telegram} 
                                                onChange={changeHandlerUpdate}
                                            />
                                        </label> 
                                        <label>
                                            Полный текст о вас*
                                            <textarea name="desc" value={form.desc} onChange={(e)=>{
                                                setForm({ ...form, "desc": e.target.value })
                                            }} />
                                        </label>
                                        <label>
                                            Документ
                                            <input 
                                                type="file"
                                                name="doc"
                                                style={{backgroundColor: "white !important", color: "black !important"}} 
                                                accept="image/*"
                                                onChange={handlerImage}
                                            />
                                        </label>
                                        <label> 
                                            Кол-во подписчиков
                                            <input 
                                                type="number"
                                                min={0}
                                                step={1} 
                                                required
                                                style={{backgroundColor: "white !important", color: "black !important"}} 
                                                name="subscribers" 
                                                value={form.subscribers} 
                                                onChange={changeHandlerUpdate}
                                            />
                                        </label>
                                        <label> 
                                            Мужчины %
                                            <input 
                                                type="number"
                                                min={0}
                                                step={1} 
                                                required
                                                style={{backgroundColor: "white !important", color: "black !important"}} 
                                                name="men" 
                                                value={form.men} 
                                                onChange={changeHandlerUpdate}
                                            />
                                        </label>
                                        <label> 
                                            Мужчины %
                                            <input 
                                                type="number"
                                                min={0}
                                                step={1} 
                                                required
                                                style={{backgroundColor: "white !important", color: "black !important"}} 
                                                name="woman" 
                                                value={form.woman} 
                                                onChange={changeHandlerUpdate}
                                            />
                                        </label>
                                        <label> 
                                            <input 
                                                type="checkbox" 
                                                checked={form.docConf} 
                                                onChange={()=>{setForm({...form, docConf: !form.docConf})}} 
                                                style={{
                                                    width: "auto",
                                                    marginRight: 5
                                                }}
                                            />
                                            Документы подтверждены
                                        </label>
                                        <label> 
                                            <input 
                                                type="checkbox" 
                                                checked={form.infoConf} 
                                                onChange={()=>{setForm({...form, infoConf: !form.infoConf})}} 
                                                style={{
                                                    width: "auto",
                                                    marginRight: 5
                                                }}
                                            />
                                            Аккаунт подтвержден
                                        </label>
                                        <label> 
                                            <input 
                                                type="checkbox" 
                                                checked={form.phoneConf} 
                                                onChange={()=>{setForm({...form, phoneConf: !form.phoneConf})}} 
                                                style={{
                                                    width: "auto",
                                                    marginRight: 5
                                                }}
                                            />
                                            Телефон подтверждены
                                        </label>
                                        <label> 
                                            <input 
                                                type="checkbox" 
                                                checked={form.CatalogConf} 
                                                onChange={()=>{setForm({...form, CatalogConf: !form.CatalogConf})}} 
                                                style={{
                                                    width: "auto",
                                                    marginRight: 5
                                                }}
                                            />
                                            Аккаунт подтвержден (Чекбок в каталоге)
                                        </label>
                                        <label> 
                                            Цена интеграции
                                            <input 
                                                type="number"
                                                min={0}
                                                step={1} 
                                                required
                                                style={{backgroundColor: "white !important", color: "black !important"}} 
                                                name="integrationCost" 
                                                value={form.integrationCost} 
                                                onChange={changeHandlerUpdate}
                                            />
                                        </label>
                                        <button>Обновить блогера</button> 
                                    </form>
                                )
                            }
                        </div>
                    </div>
                ):""
            }

            {
                delIntegrationsConf ? (
                    <div className="popup-error">
                        <div>
                            <div style={{textAlign: "right"}}>
                                <svg 
                                    width="30px" 
                                    height="30px" 
                                    viewBox="0 0 24 24" 
                                    fill="none" 
                                    xmlns="http://www.w3.org/2000/svg"
                                    style={{
                                        cursor: "pointer"
                                    }}
                                    onClick={()=>{
                                        setDelIntegrationsConf(false)
                                    }}
                                >
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M5.29289 5.29289C5.68342 4.90237 6.31658 4.90237 6.70711 5.29289L12 10.5858L17.2929 5.29289C17.6834 4.90237 18.3166 4.90237 18.7071 5.29289C19.0976 5.68342 19.0976 6.31658 18.7071 6.70711L13.4142 12L18.7071 17.2929C19.0976 17.6834 19.0976 18.3166 18.7071 18.7071C18.3166 19.0976 17.6834 19.0976 17.2929 18.7071L12 13.4142L6.70711 18.7071C6.31658 19.0976 5.68342 19.0976 5.29289 18.7071C4.90237 18.3166 4.90237 17.6834 5.29289 17.2929L10.5858 12L5.29289 6.70711C4.90237 6.31658 4.90237 5.68342 5.29289 5.29289Z" fill="#0F1729"/>
                                </svg>
                            </div>
                            <h3>Вы уверены, что хотите удалить блогеров? </h3>
                            <div className="btn-wrapper">
                                <button
                                    onClick={()=>{
                                        DelIntegrations()
                                    }}
                                >
                                    Да
                                </button>
                                <button
                                    onClick={()=>{
                                        setDelIntegrationsConf(false)
                                    }}
                                >
                                    Отменить
                                </button>
                            </div> 
                        </div>
                    </div>
                ):""
            }
        </>
    )
}