import { Link } from "react-router-dom"
import { Sellers } from "../../classes"
import axios from "axios"
import { ChangeEvent, useContext, useEffect, useState } from "react"
import { GlobalContext } from "../../context/GlobalContext"
import { Loader } from "../../component/Loader"
import { Pagination } from "../../component/Pagination"
import { SellerListItem } from "../../component/SellerListItem"
import xlsx from "json-as-xlsx"

export const SellerListPage = () => {
    const auth = useContext(GlobalContext)  
    const [sellers, setSellers] = useState(new Sellers())
    const [load, setLoad] = useState(true)
    const [loadForm, setLoadForm] = useState(true)
    const [loadForm2, setLoadForm2] = useState(true)
    const [targetItem, setTargetItem] = useState(0)
    const [search, setSearch] = useState("")
    const [showFormUpdate, setShowFormUpdate] = useState(false)




    const [form, setForm] = useState({
        name: "",
        tglogin: "",
        phone: "",
        minidesc: "",
        image: "",
        active: true,
        bDate: "",
        desc: "",
        doc: "",
        docConf: false,
        infoConf: false,
        phoneConf: false,
        goodreview: "",
        badreview: "",
        subscribeDate: "",
        integrationsCount: ""
    })
    const changeHandlerUpdate = (event: ChangeEvent<HTMLInputElement>) => {
        setForm({ ...form, [event.target.name]: event.target.value })
    }
    function handlerImage(e: any){
        setForm({ ...form, [e.target.name]: e.target.files[0] })
        console.log(e.target.files)
    }


    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => { 
        event.preventDefault()
        if(loadForm){
            setLoadForm(false)

            console.log(form)
            const formData = new FormData() 


            formData.append("name", form.name) 
            formData.append("tglogin", form.tglogin) 
            formData.append("phone", form.phone) 
            formData.append("minidesc", form.minidesc) 
            formData.append("image", form.image) 
            formData.append("active", `${form.active}`) 
            formData.append("bDate", form.bDate) 
            formData.append("desc", form.desc) 
            formData.append("doc", form.doc) 
            formData.append("docConf", `${form.docConf}`) 
            formData.append("infoConf", `${form.infoConf}`) 
            formData.append("phoneConf", `${form.phoneConf}`) 
            formData.append("goodreview", form.goodreview) 
            formData.append("badreview", form.badreview) 
            formData.append("subscribeDate", form.subscribeDate) 
            formData.append("integrationsCount", form.integrationsCount) 

            await axios.post(`https://api.blogerito.ru/dashboard/seller-update/`, formData, {
                params: {
                    'id': targetItem
                },
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': 'Token '+auth.user.userToken
                }
            }).then(res => {  
                setLoadForm(true)
                console.log(res.data)
                alert(res.data.msg) 
            }).catch(error => {
                setLoadForm(true)
                alert("error")
            })
        }
    }

    const LoadExcel = () => {
        sellers.LoadExcel(
            auth.user.userToken,
            search, 
            selectArray.toString()
        ).then(function(response){
            const data = [
                {
                    sheet: "Adults",
                    columns: [
                        { label: "id", value: "id"},
                        { label: "Email", value: "email"},
                        { label: "ФИО", value: "name"},
                        { label: "Логин Telegram", value: "tglogin"},
                        { label: "Номер телефона", value: "phone"},
                        { label: "Дата рождения", value: "bDate"},
                        { label: "Отзывы+", value: "goodreview"},
                        { label: "Отзывы-", value: "badreview"},
                        { label: "Кол-во доступных интеграций", value: "integrationsCount"},
                        { label: "Дата завершения подписки", value: "subscribeDate"},
                        { label: "Проектов создано", value: "integrations_create"},
                        { label: "Интеграции", value: "applications_proccess"},
                        { label: "Интеграций в процессе", value: "applications_work"},
                        { label: "Интеграций выполнено", value: "applications_done"},
                        { label: "Интеграций отменено", value: "applications_cancelled"},

                        { label: "Статус интеграции (Заявка принята)", value: "in_process"},
                        { label: "Статус интеграции (ТЗ принято)", value: "in_process"},
                        { label: "Статус интеграции (Ожидание реквизитов)", value: "w8-paydata"},
                        { label: "Статус интеграции (Ожидание оплаты)", value: "payment_w8"},
                        { label: "Статус интеграции (Блогер подтвердил оплату)", value: "payment_completed"},
                        { label: "Статус интеграции (Товар заказан)", value: "product_ordered"},
                        { label: "Статус интеграции (Товар получен)", value: "product_received"},
                        { label: "Статус интеграции (Пост размещен)", value: "post_posted"},
                        { label: "Статус интеграции (Блогер подтвердил выполнение)", value: "bloger_done"},
                        { label: "Статус интеграции (Ожидание отзыва от блогера)", value: "w8_bloger_feedback"},

                        { label: "Процент отмены интеграций", value: "cancelled_proc"},
                        { label: "Процент завершенных интеграций", value: "done_proc"},

                        { label: "Документы подтверждены", value: "docConf"},
                        { label: "Аккаунт подтвержден", value: "infoConf"},
                        { label: "Номер подтвержден", value: "phoneConf"}
                    ],
                    content: response
                }, 
            ]
            console.log(data)
            let settings = {
            fileName: "Селлеры"
            }
            xlsx(data, settings)
        })
    }

    const LoadData = (page = 1) => {
        setLoad(true)
        sellers.LoadData(auth.user.userToken, search, page).then(function(response){
            setLoad(false)
        })
    }
    const [selectArray, setSelectArray] = useState<number[]>([])
    const [selectAll, setSelectAll] = useState(false)
    const [delIntegrationsConf, setDelIntegrationsConf] = useState(false)

    const DelIntegrations = (array: number[]) => {
        sellers.DelItem(array, auth.user.userToken).then(function(response){
            alert(`${array.length} селлеров успешно удалено`)
            window.location.reload()
        }).catch(function(error){
            alert("error")
        })
    }


    useEffect(()=>{
        LoadData()
    }, [])

    if(load){
        return(
            <Loader />
        )
    }


    return(
        <>
            <div className="content">
                <div className="row">
                    <div className="col-md-4">
                        <div className="block-wrapper">
                            <form className="sform" onSubmit={(e)=>{
                                e.preventDefault()
                                LoadData(sellers.targetPage)
                            }}>
                                <input type="text" placeholder="Поиск" value={search} onChange={(e)=>{
                                    setSearch(e.target.value)
                                }} />
                                <button>
                                    <svg width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M15.7955 15.8111L21 21M18 10.5C18 14.6421 14.6421 18 10.5 18C6.35786 18 3 14.6421 3 10.5C3 6.35786 6.35786 3 10.5 3C14.6421 3 18 6.35786 18 10.5Z" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                </button>
                            </form>
                        </div>
                    </div>
                    <div className="col-md-8">
                        <div className="right">
                            {
                                selectArray.length ? (
                                    auth.user.userRole == "director" ? (
                                        <button 
                                            className="btn" 
                                            style={{background: "#ff4646"}}
                                            onClick={()=>{
                                                setDelIntegrationsConf(true)
                                            }}
                                        >
                                            Удалить
                                        </button>
                                    ):""
                                ):""
                            }

                            {
                                auth.user.userRole == "director" ? (
                                    <button 
                                        className="btn"
                                        style={{background: "#207444"}}
                                        onClick={()=>{
                                            LoadExcel()
                                        }}
                                    >
                                        Выгрузить в excel
                                    </button>
                                ):""
                            }
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="block-wrapper table-wrapper">
                            <div
                                style={{
                                    fontSize: 12
                                }}
                            >
                                Выбрано: {selectArray.length}
                            </div>
                            <table className="list-table">
                                <thead>
                                    <tr>
                                        <th>
                                            <div>
                                                <input 
                                                    type="checkbox"
                                                    checked={selectAll}
                                                    onClick={()=>{
                                                        const Harray: number[] = []
                                                        if(!selectAll){
                                                            sellers.list.forEach((item) => {
                                                                Harray.push(item.pk)
                                                            })
                                                        }
                                                        setSelectArray(Harray)
                                                        setSelectAll(!selectAll)
                                                    }} 
                                                />
                                            </div>
                                        </th>
                                        <th>
                                            id
                                        </th>
                                        <th>
                                            Пользователь
                                        </th>
                                        <th>
                                            ФИО
                                        </th>
                                        <th>
                                            Номер телефона
                                        </th>
                                        <th>
                                            Ник в Telegram
                                        </th>
                                        <th>
                                            Дата завершения подписки
                                        </th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        sellers.list.length ? (
                                            sellers.list.map((item, index) => {
                                                return(
                                                    <SellerListItem 
                                                        item={item}
                                                        setTargetItem={setTargetItem}
                                                        setShowFormUpdate={setShowFormUpdate}
                                                        setLoadForm2={setLoadForm2}
                                                        setFormUpdate={setForm}
                                                        setSelectArray={setSelectArray}
                                                        selectArray={selectArray}
                                                        setDelIntegrationsConf={setDelIntegrationsConf}
                                                    />
                                                )
                                            })
                                        ):"" 
                                    } 
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

                <Pagination
                    page={sellers.targetPage}
                    pageCount={sellers.countPage}
                    loadData={LoadData}
                />
            </div>


            {
                showFormUpdate ? (
                    <div className="popup-integration">
                        <div className="">
                            <div style={{textAlign: "right"}}>
                                <svg 
                                    width="30px" 
                                    height="30px" 
                                    viewBox="0 0 24 24" 
                                    fill="none" 
                                    xmlns="http://www.w3.org/2000/svg"
                                    style={{
                                        cursor: "pointer"
                                    }}
                                    onClick={()=>{
                                        setShowFormUpdate(false)
                                    }}
                                >
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M5.29289 5.29289C5.68342 4.90237 6.31658 4.90237 6.70711 5.29289L12 10.5858L17.2929 5.29289C17.6834 4.90237 18.3166 4.90237 18.7071 5.29289C19.0976 5.68342 19.0976 6.31658 18.7071 6.70711L13.4142 12L18.7071 17.2929C19.0976 17.6834 19.0976 18.3166 18.7071 18.7071C18.3166 19.0976 17.6834 19.0976 17.2929 18.7071L12 13.4142L6.70711 18.7071C6.31658 19.0976 5.68342 19.0976 5.29289 18.7071C4.90237 18.3166 4.90237 17.6834 5.29289 17.2929L10.5858 12L5.29289 6.70711C4.90237 6.31658 4.90237 5.68342 5.29289 5.29289Z" fill="#0F1729"/>
                                </svg>
                            </div>
                            {
                                loadForm2 ? (
                                    <div style={{backgroundColor: "white", borderRadius: 10, paddingTop: 20}}>
                                        <Loader />
                                    </div>
                                ):(
                                    <form onSubmit={handleSubmit}> 
                                        <label> 
                                            <input 
                                                type="checkbox" 
                                                checked={form.active} 
                                                onChange={()=>{setForm({...form, active: !form.active})}} 
                                                style={{
                                                    width: "auto",
                                                    marginRight: 5
                                                }}
                                            />
                                            Селлер активный
                                        </label>
                                        <label> 
                                            ФИО
                                            <input 
                                                type="text" 
                                                maxLength={255}
                                                required 
                                                style={{backgroundColor: "white !important", color: "black !important"}} 
                                                name="name" 
                                                value={form.name} 
                                                onChange={changeHandlerUpdate}
                                            />
                                        </label>
                                        <label> 
                                            Логин в telegram
                                            <input 
                                                type="text" 
                                                maxLength={255}
                                                required 
                                                style={{backgroundColor: "white !important", color: "black !important"}} 
                                                name="tglogin" 
                                                value={form.tglogin} 
                                                onChange={changeHandlerUpdate}
                                            />
                                        </label>
                                        <label> 
                                            Номер телефона
                                            <input 
                                                type="text" 
                                                maxLength={255}
                                                required 
                                                style={{backgroundColor: "white !important", color: "black !important"}} 
                                                name="phone" 
                                                value={form.phone} 
                                                onChange={changeHandlerUpdate}
                                            />
                                        </label>
                                        <label>
                                            Фото
                                            <input 
                                                type="file"
                                                name="image"
                                                style={{backgroundColor: "white !important", color: "black !important"}} 
                                                accept="image/*"
                                                onChange={handlerImage}
                                            />
                                        </label>
                                        <label>
                                            Краткое описание
                                            <textarea name="minidesc" maxLength={510} value={form.minidesc} onChange={(e)=>{
                                                setForm({ ...form, "minidesc": e.target.value })
                                            }} />
                                        </label>
                                        <label> 
                                            Дата рождения
                                            <input 
                                                type="date" 
                                                maxLength={255} 
                                                style={{backgroundColor: "white !important", color: "black !important"}} 
                                                name="bDate" 
                                                value={form.bDate} 
                                                onChange={changeHandlerUpdate}
                                            />
                                        </label>
                                        <label>
                                            Полный текст о вас
                                            <textarea name="desc" value={form.desc} onChange={(e)=>{
                                                setForm({ ...form, "desc": e.target.value })
                                            }} />
                                        </label>
                                        <label>
                                            Фото
                                            <input 
                                                type="file"
                                                name="doc"
                                                style={{backgroundColor: "white !important", color: "black !important"}} 
                                                accept="image/*"
                                                onChange={handlerImage}
                                            />
                                        </label>
                                        <label> 
                                            Дата завершения подписки
                                            <input 
                                                type="date" 
                                                maxLength={255} 
                                                style={{backgroundColor: "white !important", color: "black !important"}} 
                                                name="subscribeDate" 
                                                value={form.subscribeDate} 
                                                onChange={changeHandlerUpdate}
                                            />
                                        </label>
                                        <label> 
                                            <input 
                                                type="checkbox" 
                                                checked={form.docConf} 
                                                onChange={()=>{setForm({...form, docConf: !form.docConf})}} 
                                                style={{
                                                    width: "auto",
                                                    marginRight: 5
                                                }}
                                            />
                                            Документы подтверждены
                                        </label>
                                        <label> 
                                            <input 
                                                type="checkbox" 
                                                checked={form.infoConf} 
                                                onChange={()=>{setForm({...form, infoConf: !form.infoConf})}} 
                                                style={{
                                                    width: "auto",
                                                    marginRight: 5
                                                }}
                                            />
                                            Аккаунт подтвержден
                                        </label>
                                        <label> 
                                            <input 
                                                type="checkbox" 
                                                checked={form.phoneConf} 
                                                onChange={()=>{setForm({...form, phoneConf: !form.phoneConf})}} 
                                                style={{
                                                    width: "auto",
                                                    marginRight: 5
                                                }}
                                            />
                                            Телефон подтверждены
                                        </label>
                                        <label> 
                                            Количество доступных интеграций
                                            <input 
                                                type="number"
                                                min={0}
                                                step={1} 
                                                required
                                                style={{backgroundColor: "white !important", color: "black !important"}} 
                                                name="integrationsCount" 
                                                value={form.integrationsCount} 
                                                onChange={changeHandlerUpdate}
                                            />
                                        </label>

                                        <button>Обновить селлера</button> 
                                    </form>
                                )
                            }
                        </div>
                    </div>
                ):""
            }

            {
                delIntegrationsConf ? (
                    <div className="popup-error">
                        <div>
                            <div style={{textAlign: "right"}}>
                                <svg 
                                    width="30px" 
                                    height="30px" 
                                    viewBox="0 0 24 24" 
                                    fill="none" 
                                    xmlns="http://www.w3.org/2000/svg"
                                    style={{
                                        cursor: "pointer"
                                    }}
                                    onClick={()=>{
                                        setDelIntegrationsConf(false)
                                    }}
                                >
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M5.29289 5.29289C5.68342 4.90237 6.31658 4.90237 6.70711 5.29289L12 10.5858L17.2929 5.29289C17.6834 4.90237 18.3166 4.90237 18.7071 5.29289C19.0976 5.68342 19.0976 6.31658 18.7071 6.70711L13.4142 12L18.7071 17.2929C19.0976 17.6834 19.0976 18.3166 18.7071 18.7071C18.3166 19.0976 17.6834 19.0976 17.2929 18.7071L12 13.4142L6.70711 18.7071C6.31658 19.0976 5.68342 19.0976 5.29289 18.7071C4.90237 18.3166 4.90237 17.6834 5.29289 17.2929L10.5858 12L5.29289 6.70711C4.90237 6.31658 4.90237 5.68342 5.29289 5.29289Z" fill="#0F1729"/>
                                </svg>
                            </div>
                            <h3>Вы уверены, что хотите удалить селлеров? </h3>
                            <div className="btn-wrapper">
                                <button
                                    onClick={()=>{
                                        DelIntegrations(selectArray)
                                    }}
                                >
                                    Да
                                </button>
                                <button
                                    onClick={()=>{
                                        setDelIntegrationsConf(false)
                                    }}
                                >
                                    Отменить
                                </button>
                            </div> 
                        </div>
                    </div>
                ):""
            }
        </>
    )
}